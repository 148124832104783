@import "../../Styles/theme.scss";

.devices {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

@media (max-width: 1435px) {
  .devices {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: $tablet-break-point) {
  .devices {
    grid-template-columns: repeat(1, 1fr);
  }
}

.loading_sensors {
  text-align: center;
  align-self: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding-bottom: 48px;
}

.card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 32px 51px;
  background-color: $white;
}

.heading {
  color: var(--Neutral-7, #0c0d0d);

  /* Heading 4 Medium */
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px; /* 150% */
  margin-bottom: 24px;
}

.subtitle {
  color: var(--Neutral-7, #0c0d0d);

  /* Paragraph 1 Medium */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 150% */
  margin: 0 0 8px 0px;
}

.notes {
  color: var(--Neutral-6, #555a5a);

  /* Paragraph 2 Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  margin-bottom: 24px;
}

.error {
  color: $risk3;
}

.installationNotes_buttons {
  display: flex;
  justify-content: flex-end;
  margin: 12px 0px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 24px;
}

.sensor_button_group {
  display: flex;
  gap: 16px;
}

@media (max-width: $mobile-break-point) {
  .card {
    padding: 24px;
  }
  .devices {
    flex-direction: column;
  }
  .buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  .sensor_button_group {
    flex-direction: column;
  }
}
